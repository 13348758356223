import React, { useState } from "react";
import Client from "../components/Client";
import { Link } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ReactPlayer from "react-player";
import CloseButton from "../assets/scw-close.png";
import { isBrowser } from "react-device-detect";
import Header from "../components/Header";
import VolumeUp from "../assets/volume_up-24px.svg";
import VolumeOff from "../assets/volume_off-24px.svg";

const Article = (props) => {
  const [itemData, loadingItemData] = Client("portfolio", "fields.index");
  const [color, setColor] = useState("rgb(216, 216, 216)");
  const [modal, setModal] = useState({
    show: false,
    image: null,
    description: null,
  });

  if (loadingItemData) {
    return (
      <main className="text-align-center error">
        <Header color={color} />
        Loading project...
      </main>
    );
  }

  const id = itemData.items.findIndex(
    (k) => k.fields.slug === props.match.params.slug
  );

  if (id === -1) {
    return (
      <main className="text-align-center error">
        <Header color={color} />
        Error: project was not found.
      </main>
    );
  }

  const mainStyle = {
    backgroundColor: color,
  };

  const options = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  const {
    logotype,
    title,
    vimeoLink,
    heroMedia,
    media,
    description,
    themeColor,
  } = itemData.items[id].fields;

  if (color !== themeColor) {
    setColor(themeColor);
  }

  console.log(itemData.items[id].fields);
  return (
    <>
      <main style={mainStyle}>
        <Header color={color} />
        <section className="logotype-container text-align-center h5 mb2">
          {logotype ? (
            <img
              className="logotype relative center-y mw100"
              src={logotype.fields.file.url}
              alt={title}
            />
          ) : (
            <p>{title}</p>
          )}
        </section>
        <section className="media relative flex flex-wrap flex-center center-x max-width mb2 text-align-center">
          <div className="w75 xs-mb">
            {vimeoLink ? (
              <Video src={vimeoLink} />
            ) : (
              <div className="image-container">
                <img
                  className="zoom-in w100"
                  src={heroMedia.fields.file.url + "?w=1100"}
                  alt={heroMedia.fields.description}
                  onClick={() =>
                    setModal({
                      show: true,
                      image: heroMedia.fields.file.url,
                      description: heroMedia.fields.description,
                    })
                  }
                />
                {heroMedia.fields.description ? (
                  <p className="image-description mt1">
                    {heroMedia.fields.description}
                  </p>
                ) : null}
              </div>
            )}
          </div>
          {media.map(({ fields }, i) => (
            <div className="w50 xs-mb m01" key={i}>
              <div className="image-container">
                <img
                  className="zoom-in w100 h-auto"
                  src={fields.file.url + "?w=800"}
                  alt={fields.description}
                  onClick={() =>
                    isBrowser
                      ? setModal({
                          show: true,
                          image: fields.file.url,
                          description: fields.description,
                        })
                      : null
                  }
                />
              </div>
              {fields.description ? (
                <p className="image-description mt1 xs-mb">
                  {fields.description}
                </p>
              ) : null}
            </div>
          ))}
        </section>
        <section
          className="information text-align-center m40"
          style={{ maxWidth: "46em", marginLeft: "auto", marginRight: "auto" }}
        >
          {documentToReactComponents(description, options)}
        </section>
        <Link to="/">
          <div className="button inline-block relative m4060 center-x border-1px radius">
            Back to INDEX
          </div>
        </Link>
      </main>
      <Modal show={modal.show} handleClose={() => setModal({ show: false })}>
        <img
          className="modal-image"
          src={modal.image}
          alt={modal.description}
        />
        <p>{modal.description}</p>
      </Modal>
    </>
  );
};

export default Article;

const Video = (props) => {
  const [volume, setVolume] = useState(0);

  console.log(volume);

  return (
    <div className="aspect-ratio mw100 text-bottom mb1">
      <div className="loading-video absolute top left w100 h100 text-align-center">
        <span className="relative center-y">
          Loading CW video experience...
        </span>
      </div>
      <ReactPlayer
        className="main-video absolute top left"
        url={"https://player.vimeo.com/video/" + props.src}
        playing
        loop
        volume={volume}
        playsinline
        preload="true"
        width="100%"
        height="100%"
      />
      <div
        className="controls o0 transition absolute top left w100 h100"
        onClick={() => (volume === 0 ? setVolume(1) : setVolume(0))}
      >
        <div
          className={
            "absolute transition bottom right " + (volume !== 0 ? "o1" : "o0")
          }
        >
          <img src={VolumeUp} alt="Mute" className="inverted icon" />
        </div>
        <div
          className={
            "absolute transition bottom right " + (volume === 0 ? "o1" : "o0")
          }
        >
          <img src={VolumeOff} alt="Unmute" className="inverted icon" />
        </div>
      </div>
    </div>
  );
};

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "top" : "top-100";

  if (!isBrowser) {
    return null;
  }

  return (
    <div
      className={
        "modal fixed left z2 w100 h100 bWhite zoom-out " + showHideClassName
      }
      onClick={handleClose}
    >
      <section className="modal-main">{children}</section>
      <img
        className="modal-close-button"
        src={CloseButton}
        alt="Close"
        onClick={handleClose}
      />
    </div>
  );
};
