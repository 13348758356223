import { useState, useEffect } from 'react'
import { createClient } from 'contentful'

const SPACE_ID = '4dpktgl6njeq'
const ACCESS_TOKEN = 'UZt1ejmHIMRgiT2FvOj0KRdkYUpwTjDsn4HF_R2o7UQ'

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
});

const ClientHook = (contentType, order) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    client.getEntries({ content_type: contentType, order: order }).then(function(entries) {
      setData(entries)
      setLoading(false)
    });
  }, []);
  return [data, loading]
};

export default ClientHook
