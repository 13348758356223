import React, { useState, useEffect } from 'react';
import Client from '../components/Client'
import { Link } from "react-router-dom"
import Header from '../components/Header'
import { isMobile } from "react-device-detect"

const Navigation = () => {
  const [itemData, loadingItemData] = Client('portfolio', 'fields.index')
  const [color, setColor] = useState("rgb(216, 216, 216)")
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (isMobile) {
    let interval = setInterval(() => setCount(getInt(0, (!loadingItemData ? itemData.items.length : 10))), 6000)

    if (!loadingItemData) {
      const colors = itemData.items.map((entry, i) => count === i ? setColor(entry.fields.themeColor) : null)
    }

    return () => clearInterval(interval)
    }
  }, [count])

  function getInt(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
  }

  if (loadingItemData) {
    return (
      <main className="text-align-center error">
        <Header color={color} />
        Loading projects...
      </main>
    )
  }

  const mainStyle = {
    backgroundColor: color,
    transition: isMobile ? "background 6s ease-in" : "background 1500ms ease-in"
  }

  return (
    <>
      <main className="fade-in" style={mainStyle}>
        <Header color={color} />
        <div className="project-headline display-none-xs text-align-center">
          Projects<br />(in no particular order)
        </div>
        <hr />
        {itemData.items.map((entry, i) =>
          <div key={i}>
            <div className="item h4 text-align-center"
              onMouseOver={() => setColor(entry.fields.themeColor ? entry.fields.themeColor : "rgb(216, 216, 216)")}
              onMouseLeave={() => setColor("rgb(216, 216, 216)")}>
              <Link to={entry.fields.slug}>
                {entry.fields.logotype ?
                  <img className="list-logotype h7 mw100 relative center-y" src={entry.fields.logotype.fields.file.url} alt={entry.fields.title} />
                :
                  <p>
                    {entry.fields.title}
                  </p>
                }
              </Link>
            </div>
            <hr />
          </div>
        )}
      </main>
    </>
  )
}

export default Navigation
