import React from "react"
import { Link } from "react-router-dom";

const Header = (props) => {

  const shadow = {
    background: props.color,
    boxShadow: "0px -25px 20px 40px " + props.color,
    transition: "background-color 1.5s ease, box-shadow 1.5s ease"
  }

  return (
    <header className="w100 fixed flex top left justify-content p2 z1">
      <Link to="/">
        <div className="button-link text-align-center p-75 inline-block border-1px radius w14">
          Studio<br />Christopher West
        </div>
      </Link>
      <Link to="/">
        <div className="button-link text-align-center p-75 inline-block border-1px radius w14 display-none-xs">
          Graphic design<br />& Art direction
        </div>
      </Link>
    </header>
  )
}

export default Header
