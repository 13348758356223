import React from "react";
import Logotype from "../assets/scw-newlogotype.png";

const Footer = (props) => {
  return (
    <footer className={props.styling}>
      <img src={Logotype} alt="Christopher West" />
      <p>
        <a href="mailto:studio@christopherwest.se">studio@christopherwest.se</a>
        <br />
        +46 (0)739 47 65 70
      </p>
      <p>
        De Nieuwe Diepte / Berga
        <br />
        Vretensborgsvägen 15
        <br />
        126 30 Hägersten
        <br />
        Sweden
      </p>
      <hr />
      <p>
        Christopher West (b. 1982)
        <br />
        Lives and works in Stockholm, Sweden
        <br />
        Educated at Gerrit Rietveld Academie (’06–’09)
        <br />
        <br />
        CV available on request.
      </p>
      <hr />
      <p>
        Programming by <a href="https://asso.gd">Mathias Lindahl</a>
        <br />
        Typefaces: Chee by <a href="https://ohnotype.co/">Oh No</a> & Cubik by{" "}
        <a href="http://www.ourpolitesocietytype.net/">OPS Type</a>
      </p>
    </footer>
  );
};

export default Footer;
