import React from 'react';
import Navigation from './Navigation'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Article from './Article'
import Footer from '../components/Footer'
import '../assets/global.css'
import ScrollToTop from 'react-router-scroll-top'

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Navigation} />
            <Route path="/:slug" component={Article} />
            <Route component={Error} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  )
}

export default App

const Error = () => ("404")
